/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import cx from 'classnames';
import { LinkData, SocialData } from 'models/publisher/block';
import { Field, Fieldset } from 'shared/Fieldset';
import { Image } from 'shared/Image';
import { FileDropZone } from 'shared/FileDropZone';
import { TextInput } from 'shared/TextInput';
import { Trash } from 'shared/icons';
import { useToggle } from 'hooks/useToggle';
import { ImageData } from 'models/image';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Toggle } from 'DesignSystem/Form';
import { SelectImage } from './SelectImage';
import styles from './external.module.css';
import forms from '../../form.module.css';

type PropsType = {
  initial?: LinkData | SocialData;
  hasChanges: boolean;
  link: LinkData | SocialData;
  isLoading?: boolean;
  isUploading?: boolean;
  isCustomImage: boolean;
  deleteImage: () => void;
  canDeleteImage: boolean;
  setImage: (image: ImageData | File) => void;
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setCallToAction: (description: string) => void;
  setReadTime: (time: string) => void;
  setAltText: (altText: string) => void;
  onChange: (link: LinkData | SocialData) => void;
  setOpenInNewTab: (value: boolean) => void;
  imageProcessingErrored?: boolean;
};

export const FormBody: React.FC<PropsType> = ({
  link,
  isLoading,
  isUploading,
  isCustomImage,
  canDeleteImage,
  imageProcessingErrored,
  setImage,
  setTitle,
  setDescription,
  setCallToAction,
  setAltText,
  setReadTime,
  setOpenInNewTab,
}) => {
  const isDragging = useToggle();
  const imageRemoveVisibility =
    canDeleteImage &&
    link.image.url &&
    !isLoading &&
    !isUploading &&
    !isDragging.value;

  const showOpenInNewTab = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.ShowOpenLinksInNewTab'
  ).data?.value;

  return (
    <>
      <Fieldset className={cx(styles.lastFieldset, forms.fieldset)}>
        <Field
          label="Title"
          className={cx(
            styles.title,
            styles.field,
            forms.field,
            styles.linkField
          )}
        >
          <textarea
            className={styles.input}
            dir="auto"
            data-test="link-edit-title"
            value={link.title.value}
            onPaste={(event) => {
              const pasted = event.clipboardData.getData('text');
              event.preventDefault(); // Prevent default paste behavior
              setTitle(pasted); // Update state directly
            }}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Field>
        {link.type === 'link' && (
          <Field
            label="Description"
            className={cx(
              styles.description,
              styles.field,
              forms.field,
              styles.linkField
            )}
          >
            <textarea
              value={link.description.value}
              className={styles.input}
              dir="auto"
              onChange={(event) => setDescription(event.target.value)}
            />
          </Field>
        )}
        {link.type === 'link' && (
          <Field
            label="Call to action"
            className={cx(
              styles.callToAction,
              styles.field,
              forms.field,
              styles.linkField
            )}
          >
            <TextInput
              value={link.callToAction?.value || ''}
              onChange={setCallToAction}
              dir="auto"
              maxLength={100}
              className={styles.input}
              autoComplete="off"
            />
          </Field>
        )}
        <Field
          label="Image"
          className={cx(styles.imageField, styles.field, forms.field)}
        >
          <div style={{ position: 'relative' }}>
            {}
            <FileDropZone
              accept="image/*"
              onFileSelect={setImage}
              shouldClear={!link.image.url || !isCustomImage}
              onDragEnd={isDragging.disable}
              onDragStart={isDragging.enable}
              btnClassName={cx(styles.imageUpload, {
                [styles.custom]:
                  isLoading ||
                  isUploading ||
                  isCustomImage ||
                  imageProcessingErrored ||
                  link.images.length <= 1 ||
                  isDragging.value,
              })}
            >
              {!imageProcessingErrored && (
                <Image
                  width="100%"
                  height="100%"
                  isUploading={isUploading || isLoading}
                  url={link.image.url}
                  loadingClassName={styles.loading}
                  imageClassName={styles.image}
                  placeholderClassName={styles.placeholder}
                  placeholderIconName="ImagePlaceholder"
                />
              )}
              {imageProcessingErrored && (
                <div className={styles.unableToProcess}>
                  We are unable to access the image for this link due to the
                  link’s settings. Please upload an image or try a different
                  link.
                </div>
              )}
              {imageRemoveVisibility && !imageProcessingErrored && (
                <div className={styles.removeImage}>
                  <div
                    onClick={(e) => {
                      if (isCustomImage) {
                        const url = link.images[0]?.url || '';
                        setImage({
                          url,
                          processed: false,
                        });
                        if (url) e.stopPropagation();
                      }
                    }}
                  >
                    <Trash />
                  </div>
                </div>
              )}
            </FileDropZone>
          </div>
          {!isLoading &&
            !isDragging.value &&
            !isCustomImage &&
            !imageProcessingErrored &&
            link.images.length > 1 && (
              <SelectImage
                current={link.image}
                setImage={setImage}
                images={link.images}
              />
            )}
        </Field>
        <Field
          label="Alt Text"
          className={cx(
            styles['alt-text'],
            styles.field,
            forms.field,
            styles.linkField
          )}
        >
          <TextInput
            value={link.image?.altText || ''}
            onChange={setAltText}
            className={styles.input}
            autoComplete="off"
            dir="auto"
          />
        </Field>
        {showOpenInNewTab && (
          <Field
            className={cx(styles.field, forms.field, styles.linkField)}
            label="Open in new tab?"
          >
            <Toggle
              on={link.openInNewTab}
              onChange={(openInNewTab) => setOpenInNewTab(!!openInNewTab)}
              size="xl"
            />
          </Field>
        )}
        {link.type === 'link' && (
          <Field
            label="Read time (in seconds)"
            className={cx(
              styles['alt-text'],
              styles.field,
              forms.field,
              styles.linkField
            )}
          >
            <TextInput
              value={
                link.readTimeInSeconds && link.readTimeInSeconds > 0
                  ? `${link.readTimeInSeconds}`
                  : ''
              }
              onChange={setReadTime}
              className={styles.input}
              autoComplete="off"
              number
              maxLength={5}
            />
          </Field>
        )}
      </Fieldset>
    </>
  );
};
